import HeroImg from '../assets/hero.png';
import { AiOutlineTwitter, AiOutlineFacebook, AiOutlineLinkedin } from "react-icons/ai";

export default function Hero() {
    const config  = {
        subtitle: "Im a Propirator of ",                    
        subtitle1:"Bharath Tecnoloy Solutions",
        subtitle2: "Full-stack developer and Designer",
        social: {
            twitter: 'https://twitter.com/bceramu',
            facebook: 'https://facebook.com/bceramu',
            linkedin: 'https://in.linkedin.com/company/bceramu'
        }
    }


    return <section className='flex flex-col md:flex-row px-5 py-32 bg-primary justify-center'>
       <div className='md:w-1/2 flex flex-col '>
            <h1 className=' text-gray-300 text-6xl font-hero-font'>Hi, <br/> Im <span className='text-blue'>M.</span> Ramu 
                <p className='text-2xl'>{config.subtitle} <h3 className='max-w-xs transition duration-300 ease-in-out hover:text-blue-700 animate-pulse text-orange-500'> {config.subtitle1} </h3>{config.subtitle2}</p>
            </h1>
            <div className='flex py-10'>
                <a href={config.social.twitter} className='pr-5 text-blue-700 hover:text-green-100'><AiOutlineTwitter size={40} /></a>
                <a href={config.social.facebook} className='pr-5 text-blue-700 hover:text-green-100'><AiOutlineFacebook size={40}/></a>
                <a href={config.social.linkedin} className='text-blue-700 hover:text-green-100'><AiOutlineLinkedin size={40}/></a>
            </div>
       </div>
       <img className='md:w-1/5 h-auto transition duration-300 ease-in-out hover:scale-110' src={HeroImg} />
    </section>
}
