import AboutImg from '../assets/v.png';

export default function AboutUs() {
    const config = {
        line1: 'We all know that in the emerging 5G (Fifth generation) everybody wants to shop from their home through internet. In future, your business has to be done through internet because web pages are you Virtual Showrooms.',
        line2: 'We are going to have a database which will contain more than 10,000 data of different businesses from various domains in Udumalpet. We are going to have nearly 5,000 viewers who will view our website in a day. If you have data in our website, it will reach people. By doing so we promote & sale your products and services directly.',
        line3: 'If you want to have your own website or if you have one, you have to market your website rather than products.'
    }

    return <section className='flex flex-col md:flex-row bg-secondary px-4' id='aboutUs'>
        <div className='py-5 md:w-1/2'>
            <img className=' flex pb-10 h-[500px] w-[500px] ' src={AboutImg} />
        </div>
        <div className='md:w-1/2 flex justify-center'>
            <div className='flex flex-col justify-center text-white'>
                <h1 className='text-4xl border-b-4 border-primary mb-5 w-[170px] font-bold'>About Us</h1>
                <p className='pb-5'>{config.line1}</p>
                <p className='pb-5'>{config.line2}</p>
                <p className='pb-5'>{config.line3}</p>
                <p className='md:w-1/1 flex justify-center' ><strong>WHY CHOOSE US?</strong></p>
			<ul>
				<li>We promote your Business, Products and Services to make you leaders in your business.</li>
				<li>Get a Web Page for Rs.1000 Onwards.</li>
				<li>More Viewers.</li>
				<li>We make the people in need of your products & Services as your customers.</li>
				<li>We help your promotions with e-cash.</li>
				<li>We also promote you through Web Ads, animated pictures and videos, Scrolling Ads etc.</li>
			</ul>
            <br></br>
            </div>
        </div>
    </section>
}