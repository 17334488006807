import websiteImg1 from '../assets/aspnet.png';
import websiteImg2 from '../assets/ieee.png';
import websiteImg3 from '../assets/cshapnet.png';
import websiteImg4 from '../assets/android.png';
import websiteImg5 from '../assets/php.png';
import vb from '../Pdf/VB.Net.pdf';
import ieee from '../Pdf/IEEE.pdf';
import asp from '../Pdf/ASP.Net.pdf';
export default function Projects() {

    const config = {
        projects : [
            {
                image: websiteImg1,
                description: 'Asp.net Project. Built with Non-Ieee.',
                link: asp
            },
            {
                image: websiteImg2,
                description: '.Net Project. Built with Ieee.',
                link: ieee
            },
            {
                image: websiteImg3,
                description: 'C#.Net Project. Built with Non-Ieee',
                link: vb
            },
            {
                image: websiteImg4,
                description: 'Android Project. Built with Mobile',
                link: 'https://github.com/bceramu/btscart'
            },{
                image: websiteImg5,
                description: 'php Project. Built with Non-Ieee',
                link: 'https://github.com/bceramu/btscart'
            }
        ]
    }

    return <section id='projects' className="flex  flex-col py-20 px-5 justify-center bg-primary text-white animate-rotate-x animate-thrice">
        <div className="w-full">
            <div className="flex flex-col px-10 py-5">
                <h1 className="text-4xl border-b-4 border-secondary mb-5 w-[150px] font-bold">Projects</h1>
                <p>These are some of my best projects. I have built these with React, MERN and vanilla CSS. Check them out.</p>
            </div>
        </div>
        <div className="w-full ">
            <div className='flex flex-col md:flex-row px-10 gap-5 '>
                {config.projects.map((project) => (
                     <div className='relative'>
                        <img className= 'flex h-[200px] w-[300px] ' alt='i' src={project.image }/>
                        <div className='project-desc'>
                            <p className='text-center px-5 py-5'>{project.description}</p>
                            <div className='flex justify-center '>
                                <a className='btn ' rel="noreferrer" target='_blank' href={project.link}>View Project</a>
                            </div>
                        </div>
                    </div>
                ))}
               
               
            </div>
        </div>
    </section>
}