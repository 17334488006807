import './App.css';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Header from './components/Header';
import Hero from './components/Hero';
import Projects from './components/Projects';
//import Resume from './components/Resume';
import AboutUs from './components/AboutUs'


function App() {
  return (
    <div className="App">
        <Header/>
        <Hero />
        <About/>
        <br className='bg-primary'></br>
        <AboutUs/>
        <Projects/>
        <Contact/>
        <Footer/>
    </div>
  );
}

export default App;
